<template>
  <div v-if="isCreate" class="cr-title-tooltip" :class="{ show }">
    클릭하여 프로젝트의 색상을 지정해주세요.

    <v-btn icon x-small outlined color="white" @click="close">
      <v-icon size="12">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-title-tooltip::v-deep {
  display: inline-flex;
  position: absolute;
  top: -11px;
  left: 2px;
  color: #ffffff;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  padding: 5px 34px 5px 12px;
  // animation
  opacity: 0;
  // 초기에는 아래로 이동한 상태로 설정
  transform: translateY(20px);
  transition: opacity 0.8s ease-in-out, transform 0.5s ease-in-out;

  &.show {
    opacity: 0.9;
    // 나타나면서 원래 위치로 이동
    transform: translateY(0);
  }
  // 화살표
  &:after {
    content: "";
    position: absolute;
    top: 32px;
    left: 12px;
    border-top: 10px solid rgba(97, 97, 97, 0.9);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
  }

  .v-btn {
    position: absolute;
    right: 6px;
  }
}
</style>

<script>
export default {
  created() {
    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  data() {
    return { isCreate: true, show: false };
  },
  methods: {
    close() {
      this.show = false;
      setTimeout(() => {
        this.isCreate = false;
      }, 500);
    }
  }
};
</script>
